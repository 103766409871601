import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { map, of } from 'rxjs';
import { RoomsApiService } from '../services/api/rooms-api.service';

export const RoomBreadcrumbResolver: ResolveFn<string | undefined> = (
  route: ActivatedRouteSnapshot
) => {
  const roomId = Number(route.paramMap.get('roomId'));
  if (roomId == null || isNaN(roomId)) {
    return of(undefined);
  }
  return roomId
    ? inject(RoomsApiService)
        .getRoomsByIds([roomId], 2)
        .pipe(map((room) => room?.at(0)?.name))
    : of(undefined);
};
