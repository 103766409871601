import { authGuard } from '../../core/guards/auth.guard';
import { installerOrAdminGuard } from '../../core/guards/installer-or-admin-role.guard';
import {
  ENavigationRoute,
  ENavigationUnitComponentSelection,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';
import { adminGuard } from '../dashboard/guards/admin.guard';

export const REQUEST_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.REQUESTS,
    canActivate: [authGuard],
    loadComponent: () =>
      import('./pages/requests-list/requests-list.component').then(
        (x) => x.RequestsListComponent
      ),
    data: {
      label: 'REQUEST.HEADING',
      componentSelection: [
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.SIDENAV,
      ],
      visibleForUsers: [EUserRole.ROLE_ADMIN, EUserRole.ROLE_INSTALLER],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.ALL,
      },
      {
        path: ENavigationRoute.ALL,
        canActivate: [installerOrAdminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-all/requests-tab-all.component'
          ).then((x) => x.RequestsTabAllComponent),
        data: {
          label: 'REQUEST.LIST.TAB.ALL',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN, EUserRole.ROLE_INSTALLER],
        },
      },
      {
        path: ENavigationRoute.INSTALLER_ASSIGNMENT,
        canActivate: [adminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-assignment/requests-tab-assignment.component'
          ).then((x) => x.RequestsTabAssignmentComponent),
        data: {
          label: 'REQUEST.LIST.TAB.ASSIGNMENT',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN],
        },
      },
      {
        path: ENavigationRoute.INSTALLER_ACCEPTANCE,
        canActivate: [installerOrAdminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-acceptance/requests-tab-acceptance.component'
          ).then((x) => x.RequestsTabAcceptanceComponent),
        data: {
          label: 'REQUEST.LIST.TAB.ACCEPTANCE',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN, EUserRole.ROLE_INSTALLER],
        },
      },
      {
        path: ENavigationRoute.VALUATION,
        canActivate: [installerOrAdminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-valuation/requests-tab-valuation.component'
          ).then((x) => x.RequestsTabValuationComponent),
        data: {
          label: 'REQUEST.LIST.TAB.VALUATION',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN, EUserRole.ROLE_INSTALLER],
        },
      },
      {
        path: ENavigationRoute.INSTALLATION,
        canActivate: [installerOrAdminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-installation/requests-tab-installation.component'
          ).then((x) => x.RequestsTabInstallationComponent),
        data: {
          label: 'REQUEST.LIST.TAB.INSTALLATION',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN, EUserRole.ROLE_INSTALLER],
        },
      },
      {
        path: ENavigationRoute.CONFIGURED,
        canActivate: [adminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-configured/requests-tab-configured.component'
          ).then((x) => x.RequestsTabConfiguredComponent),
        data: {
          label: 'REQUEST.LIST.TAB.CONFIGURED',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN],
        },
      },
      {
        path: ENavigationRoute.REJECTED,
        canActivate: [adminGuard],
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-rejected/requests-tab-rejected.component'
          ).then((x) => x.RequestsTabRejectedComponent),
        data: {
          label: 'REQUEST.LIST.TAB.REJECTED',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
          visibleForUsers: [EUserRole.ROLE_ADMIN],
        },
      },
    ],
  },
  {
    path: `${ENavigationRoute.REQUESTS}/${ENavigationRoute.INSTALLER}/${ENavigationRoute.ASSIGNMENT}/:ids`,
    canActivate: [authGuard, adminGuard],
    loadComponent: () =>
      import(
        './pages/installer-assignment/installer-assignment.component'
      ).then((x) => x.InstallerAssignmentComponent),
  },
];
